<template>
  <b-container
    fluid
    style="
      background-image: url('../assets/img/pattern.png');
          repeat,
        linear-gradient(#e1e4e7, #f3f4f5);
      height: 100vh;
    "
  >
    <b-row class="justify-content-md-center">
      <b-col col lg="2"></b-col>
      <b-col cols="12" md="auto">
        <b-card-group
          style="
            margin: 100px auto;
            width: 400px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          "
        >
          <b-card header-class="headerStyle" footer-class="footerStyle">
            <template #header>
              <img
                src="../assets/img/logo.png"
                class="imageLogo"
              >
              <p style="font-size: 18px">Web客户端登录</p>
            </template>
            <b-card-text>
              <b-input-group size="lg" class="mb-2" style="padding: 12px 0px">
                <b-input-group-prepend is-text>
                  <b-icon icon="person-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  placeholder="帐号"
                  style="font-size: 14px; height: 50px"
                  v-model="account"
                ></b-form-input>
              </b-input-group>
              <b-input-group size="lg" class="mb-2" style="padding-bottom: 6px">
                <b-input-group-prepend is-text>
                  <b-icon icon="lock-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  placeholder="密码"
                  style="font-size: 14px; height: 50px"
                  v-model="password"
                ></b-form-input>
              </b-input-group>
              <b-alert
                :show="showError"
                variant="danger"
                dismissible
                @dismissed="showError = false"
                fade
                >{{ errorText }}</b-alert
              >
            </b-card-text>
            <template #footer>
              <b-button
                block
                variant="success"
                style="padding: 12px 10px"
                @click="onLogin"
                >登录</b-button
              >
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col col lg="2"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import md5 from "md5";
export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
      showError: false,
      errorText: "",
    };
  },
  methods: {
    onLogin() {
      let that = this;
      that
        .$http({
          url: "api/user/login",
          method: "post",
          data: {
            account: that.account,
            password: md5(that.password),
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            if (localStorage.account !== that.account) {
              console.log("not equesl");
              that.$store.commit("clearMessageCache");
            }
            that.$store.commit("setLoginAccount", data.data);
            that.$store.commit("setToken", data.token);
            that.$router.push({ name: "ChatWindow" });
          } else {
            that.showError = true;
            that.errorText = "手机号码或登录密码错误";
          }
        });
    },
  },
};
</script>

<style scoped>
.headerStyle {
  background: #ffffff;
}

.footerStyle {
  background: #ffffff;
  padding: 20px 50px;
}

.btn-success {
  background-color: #28a745;
}

.imageLogo {
  border-radius: 50%;
  width: 100px;
  margin: 30px 0px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
</style>