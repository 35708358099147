import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import router from './router'
import axios from "axios";
import Qs from "qs";
import store from "./store/store.js"
import VueToastify from "vue-toastify";
import JSONbig from "json-bigint";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.use(VueToastify,{
  my: {
    position:"top-right"
  }});

axios.defaults.transformRequest = [function (data) {
  if (data.toString() == "[object FormData]") {
    return data;
  } else {
    return Qs.stringify(data)
  }
}];
axios.defaults.transformResponse=[(data) => {
  if (typeof data === 'string') {
    try {
      data = JSONbig.parse(data);
    } catch (e) { /* Ignore */ } // Added this Ignore as it's the same in the Axios
  }
  return data;
}];

// Vue.prototype.$baseURL = "https://dnjlchatapp.hk9999z.com/";  // for test

Vue.prototype.$baseURL = "https://chat.jlzq88.com/";   // for live

Vue.prototype.$baseStoragePath = Vue.prototype.$baseURL+"files/chat-space/";

const instance = axios.create({
  baseURL: Vue.prototype.$baseURL,//"https://dnjlchatapp.hk9999z.com/",
  timeout: 30000,
  withCredentials: true
});
Vue.prototype.$http = instance;

new Vue({
  router,store,
  beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  render: h => h(App)
}).$mount('#app')