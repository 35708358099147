<template>
  <div>
    <b-modal
      id="modal-scoped_appInfo"
      title="PC Agent Client"
      centered
      :hide-footer="true"
      size="sm"
      button-size="sm"
    >
      <template>
        <p class="appDetail">版本：<span> 0.0.1</span></p>
        <p class="appDetail">版权所有：<span>Wonder Software Engineering</span></p>
      </template>

      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()"> OK </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "AppInfo",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.appDetail {
  color: #2c3e50;
  font-size: 16px;
}
</style>

