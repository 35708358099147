var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-scoped_channelAddSearch","size":"xl"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v("添加会员")])]},proxy:true},{key:"default",fn:function(){return [_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form',{attrs:{"inline":""}},[_c('label',{staticClass:"mr-sm-4"},[_vm._v("搜索单个用户:")]),_c('b-form-input',{attrs:{"aria-label":"First name"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form',{attrs:{"inline":""}},[_c('label',{staticClass:"mr-sm-4"},[_vm._v("选择代理:")]),_c('b-form-select',{staticStyle:{"width":"50%"},attrs:{"options":_vm.allAgentList,"text-field":"name","value-field":"id"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":0}},[_vm._v("所有客服")])]},proxy:true}]),model:{value:(_vm.selectedAgent),callback:function ($$v) {_vm.selectedAgent=$$v},expression:"selectedAgent"}})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.smartSearch}},[_c('b-icon',{attrs:{"icon":"search","aria-label":"Help"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{on:{"change":_vm.selectAllMembers},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}},[_vm._v("选择全部")])],1)],1),_c('b-modal',{attrs:{"centered":"","title":"确认"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){cancel();
              _vm.selectAll = false;}}},[_vm._v(" 取消 ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){_vm.addUserToGroup('', 1, _vm.selectedAgent, _vm.query);
              _vm.selectAllModal = false;
              _vm.selectAll = false;
              _vm.addGroupMembersModal = false;}}},[_vm._v(" 确认 ")])]}}]),model:{value:(_vm.selectAllModal),callback:function ($$v) {_vm.selectAllModal=$$v},expression:"selectAllModal"}},[_c('p',{staticClass:"my-4"},[_vm._v("您确认添加所有成员？")])]),_c('b-row',{staticStyle:{"height":"650px","overflow-y":"auto","padding-top":"15px"}},[_c('b-table-simple',{attrs:{"current-page":_vm.currentPage,"responsive":""}},[_c('b-tbody',[_vm._l((_vm.channelMemberList),function(i){return _c('b-tr',{key:i.id},[_c('b-td',{staticStyle:{"width":"23%","padding-left":"0px"}},[_c('b-avatar',{attrs:{"src":i.avatar}})],1),_c('b-td',{staticStyle:{"width":"24%","line-height":"40px"}},[_vm._v(_vm._s(i.name === "" ? "佚名" : i.name))]),_c('b-td',{staticStyle:{"width":"24%","line-height":"40px"}},[_vm._v(_vm._s(i.account))]),_c('b-td',{staticStyle:{"width":"23%","line-height":"40px"}},[_vm._v(_vm._s(i.telephone))]),_c('b-td',{staticStyle:{"padding-right":"25px","padding-top":"15px"}},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.onUserAddListCheckbox(i)}},model:{value:(i.selected),callback:function ($$v) {_vm.$set(i, "selected", $$v)},expression:"i.selected"}})],1)],1)}),(_vm.channelMemberList.length == 0)?_c('b-tr',[_c('b-td',[_c('p',{staticStyle:{"text-align":"center","margin":"30px"}},[_vm._v("无结果")])])],1):_vm._e(),_c('b-overlay',{staticStyle:{"z-index":"20000"},attrs:{"show":_vm.isShowLoaderPagination,"no-wrap":""}})],2)],1),(_vm.total_rows > 1)?_c('b-pagination',{staticStyle:{"position":"absolute","bottom":"-60px","width":"200px"},attrs:{"total-rows":_vm.total_rows,"aria-controls":"member_table"},on:{"change":_vm.pageChangeSmartSearch},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
              var cancel = ref.cancel;
              var ok = ref.ok;
return [_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v(" 取消 ")]),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(" 确认 ")])]}}])},[_c('b-overlay',{staticStyle:{"z-index":"20000"},attrs:{"show":_vm.isShowLoader,"no-wrap":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }