<template>
  <div>
    <b-modal id="modal-scoped_channelUserPermission" size="xl">
      <b-overlay
        :show="isShowLoader"
        no-wrap
        style="z-index: 20000"
      ></b-overlay>
      <template #modal-header>
        <h5>群组成员名单</h5>
      </template>

      <template #default>
        <b-row>
          <b-col cols="5">
            <b-form inline>
              <label class="mr-sm-4">搜索用户:</label>
              <b-form-input aria-label="user" v-model="query"></b-form-input>
              <b-button variant="primary" style="margin-left: 10px">
                <b-icon
                  icon="search"
                  aria-label="username"
                  @click="
                    currentPage = 1;
                    loadChannelMembers();
                  "
                ></b-icon>
              </b-button>
            </b-form>
          </b-col>
          <b-col cols="7"> </b-col>
        </b-row>
        <b-row><b-col cols="12"></b-col></b-row>
        <b-row style="height: 700px; overflow-y: auto; padding-top: 15px">
          <b-table-simple :current-page="currentPage" responsive>
            <b-tbody>
              <b-tr v-for="i in allChannelMembers" :key="i.id">
                <b-td style="width: 25%; padding-left: 0px">
                  <b-avatar v-bind:src="i.avatar"></b-avatar
                ></b-td>
                <b-td style="width: 25%; line-height: 40px">{{
                  i.name === "" ? "佚名" : i.name
                }}</b-td>
                <b-td style="width: 25%; line-height: 40px">{{
                  i.account
                }}</b-td>
                <b-td
                  style="text-right; padding-right: 25px; padding-top: 15px;"
                >
                  <b-form-select
                    v-model="i.host"
                    :options="options"
                    class="mb-3"
                    @change="updatePermission(i)"
                  >
                  </b-form-select>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-overlay
              :show="isShowLoaderPagination"
              no-wrap
              style="z-index: 20000"
            ></b-overlay>
          </b-table-simple>
          <b-pagination
            v-if="total_rows > 1"
            style="position: absolute; bottom: -60px; width: 200px"
            v-model="currentPage"
            :total-rows="total_rows"
            aria-controls="member_table"
            @change="pageChange"
          ></b-pagination>
        </b-row>
      </template>

      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确认 </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChannelPermission",
  props: ["selectedUser"],
  data() {
    return {
      selectOptions: null,
      options: [
        { value: "0", text: "读权权限" },
        { value: "1", text: "管理权限" },
        { value: "2", text: "发送权限" },
        { value: "3", text: "禁言成员" },
      ],
      allChannelMembers: "",
      currentPage: 1,
      total_rows: 0,
      showMyUser: false,
      query: "",
      isShowLoaderPagination: false,
      isShowLoader: false,
    };
  },
  methods: {
    loadChannelMembers() {
      let that = this;
      this.isShowLoaderPagination = true;
      this.isShowLoader = true;
      that
        .$http({
          url:
            "api/group/member/list/" +
            that.selectedUser.id +
            "/" +
            (that.currentPage - 1) +
            "?type=1",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            type: 1,
            keyword: that.query,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
            that.allChannelMembers = res.data.dataList;

            // this.showGroupMembersModal = true;
            that.total_rows = res.data.page.count;
          } else {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
          this.isShowLoaderPagination = false;
        });
    },
    pageChange(page) {
      this.currentPage = page;
      this.allChannelMembers = [];
      this.loadChannelMembers();
    },
    updatePermission(item) {
      let that = this;
      that
        .$http({
          url:
            "api/group/member/changeRole/" +
            that.selectedUser.id +
            "/" +
            item.host,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            account: item.account,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$bvModal.msgBoxOk("成功更改权限", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }
        });
    },
  },
  mounted: function () {},
};
</script>
<style scoped>
</style>
