<template>
  <div>
    <b-modal
      id="modal-scrollable_history"
      scrollable
      title="聊天记录"
      :hide-footer="true"
      size="lg"
    >
      <div class="chatWindowBody">
        <ul class="chatWindowBodyUl">
          <!-- ---------------------------------- Send Recieve message UI ------------------------------------>
          <li v-for="(item, index) in historyData" :key="index">
            <div
              v-bind:class="[
                item.sender === selectedUser.id ? 'receive' : 'sent',
              ]"
            >
              <img
                :src="item.senderAvatar"
                v-bind:class="[
                  item.sender === selectedUser.id ? 'imageleft' : 'imageright',
                ]"
              />
              <div class="talktext">
                <p
                  v-if="item.format == 0"
                  v-html="
                    processContent(
                      item.content.replace(/(?:\r\n|\r|\n)/g, '<br />')
                    )
                  "
                ></p>
                <b-img
                  v-if="item.format == 1"
                  :src="$baseStoragePath + JSON.parse(item.content).image"
                  @click="
                    showImg($baseStoragePath + JSON.parse(item.content).image)
                  "
                  style="width: auto; height: 200px; max-width: 600px"
                ></b-img>
                <!-- ================================= voice record messages ========================= -->
                <audio
                  controls
                  v-if="item.format == 2"
                  :src="$baseStoragePath + JSON.parse(item.content).file"
                  type="audio/mpeg"
                ></audio>
                <b-embed
                  v-if="item.format == 8"
                  type="video"
                  aspect="4by3"
                  controls
                >
                  <source
                    :src="$baseStoragePath + JSON.parse(item.content).video"
                    type="video/mp4"
                  />
                </b-embed>
                <div v-if="item.format == 3" @click="onFile(item.content)">
                  <img
                    v-bind:src="getFileType(item.content)"
                    style="width: auto; height: 100px"
                  />
                  <p style="text-align: right; color: #ced4da; font-size: 13px">
                    {{ JSON.parse(item.content).name }}
                  </p>
                </div>
                <p style="text-align: right; color: #ced4da; font-size: 13px">
                  {{ getTime(item.timestamp) }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div class="chatHistory" id="userChatLog" v-if="loadMoreButton">
          <b-button style="background: #2c3e50" @click="loadHistory">
            查看更多记录
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);
export default {
  name: "History",
  props: ["selectedUser"],
  data() {
    return {
      loadMoreButton: false,
      sendImage: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx1e3Brs1-mXteyLcxqZxVXQQhMQLKUcnLxA&usqp=CAU",
      ],
      historyPageNo: 0,
      historyData: [],
      historyDataTemp: [],
      emojiIcons: [
        {
          text: this.$baseURL + "/resource/layim/images/face/0.png",
          key: "[微笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/1.png",
          key: "[爱你]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/2.png",
          key: "[拜拜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/3.png",
          key: "[悲伤]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/4.png",
          key: "[鄙视]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/5.png",
          key: "[闭嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/6.png",
          key: "[馋嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/7.png",
          key: "[吃惊]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/8.png",
          key: "[鼓掌]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/9.png",
          key: "[哈哈]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/10.png",
          key: "[害羞]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/11.png",
          key: "[黑线]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/12.png",
          key: "[可爱]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/13.png",
          key: "[可怜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/14.png",
          key: "[亲亲]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/15.png",
          key: "[生病]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/16.png",
          key: "[偷笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/17.png",
          key: "[挖鼻]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/18.png",
          key: "[委屈]",
        },
      ],
    };
  },
  methods: {
    loadHistory() {
      let that = this;
      let elmentLoadMore = document.getElementById("userChatLog");
      if (!elmentLoadMore) {
        that.historyPageNo = 0;
      } else {
        that.historyPageNo++;
      }
      that
        .$http({
          url:
            "api/message/historyOneWeb/" +
            that.selectedUser.account +
            "/" +
            that.historyPageNo,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            that.historyDataTemp = res.data.data.DataList;
            if (that.historyPageNo != 0) {
              that.historyData = that.historyData.concat(that.historyDataTemp);
            } else {
              that.historyData = that.historyDataTemp;
            }
            //  Hide/show loadmore button
            if (that.historyDataTemp.length >= 20) {
              that.loadMoreButton = true;
            } else {
              that.loadMoreButton = false;
            }
          }
        });
    },
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileType(content) {
      let fileNameArray = JSON.parse(content).name.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },
    onFile(content) {
      let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(url);
    },
    showImg(url) {
      this.$hevueImgPreview(url);
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          imgPath.text +
          '">'
        );
      });
      return content;
    },
  },
};
</script>
<style scoped>
.chatWindowBody ul {
  padding-left: 0px;
}

.chatWindowBody ul li {
  list-style: none;
}

.imageleft {
  width: 30px;
  border-radius: 50%;
  float: left;
  margin: 6px 8px 0 0;
  height: 30px;
}

.sent {
  margin: 15px 0px;
  width: 100%;
  display: inline-block;
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}
.talktext {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 500px;
  text-align: justify;
}

.receive {
  float: left;
  margin: 15px 0px;
  width: 100%;
  display: inline-flex;
}

.chatHistory {
  text-align: center;
}
</style>
<style>
.hevue-imgpreview-wrap {
  z-index: 10000;
}
</style>
